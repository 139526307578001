<template>
<div class="about">

</div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>
<style scoped>
.about{
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("@/assets/img_02.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
</style>