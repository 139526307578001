import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/homePage.vue'
import NumMaster from '@/views/NumMaster.vue'
import About from '@/views/AboutUs.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', component: Home },
  { path: '/about', component: About },
  { path: '/numMaster', component: NumMaster },
  
]

const router = new VueRouter({
  routes
})

export default router
