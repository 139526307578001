<template>
  <div id="app">
    <top-menu />
    <router-view />
    <FooterContent />
  </div>
</template>

<script>
import TopMenu from "@/views/components/TopMenu.vue";
import FooterContent from "@/views/components/FooterContent.vue";

export default {
  components: {
    TopMenu,
    FooterContent,
  },
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
p {
  margin: 0;
}
</style>
