<template>
  <div class="about">
    <div class="text">
      <p
        style="
          font-family: Arial;
          font-size: 60px;
          line-height: 60px;
          text-align: left;
        "
      >
        Make More
      </p>
      <p
        style="
          font-family: Arial;
          font-size: 76px;
          font-weight: 900;
          line-height: 76px;
          text-align: left;
          margin: 10px 0 24px ;
        "
      >
        Great Games
      </p>
      <p
        style="
          font-family: Arial;
          font-size: 22px;
          font-weight: 700;
          line-height: 22px;
          text-align: left;
        "
      >
        Create games that are fun and engaging for players
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.about {
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("@/assets/img_01.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
.text {
  margin-left: 9%;
  color: #fff;
  font-weight: 700;
}

</style>
